import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useEffect } from "react";
import { checkWindow } from "@vfit/shared/data-access";
import { useCookie } from "@vfit/shared/hooks";
import { useRouter } from "next/router";
var CookieRedirect = function() {
    var asPath = useRouter().asPath;
    var isHomepage = asPath === "/";
    var ref = _sliced_to_array(useCookie("session-domain", ""), 1), cookie = ref[0];
    useEffect(function() {
        if (checkWindow() && isHomepage && cookie && cookie !== window.origin) window.location.href = cookie.toString();
    }, []);
    return null;
};
export default CookieRedirect;
