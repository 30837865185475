import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { handleUseQuery, LoggerInstance, serverlessClient } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { errorMock } from "../../utils/utils";
/**
 * This method is used retrieve the availability for the refund of gthe fixed line number from ApicWeb.
 * This in used in the BillingPage component
 * Method: GET
 * SearchParams: salesChannel: selfService
 * @returns
 */ var checkRefundService = function(fixedLine, customOptions) {
    return serverlessClient.get(API.CHECK_REFUND, _object_spread({
        searchParams: {
            fixedLine: fixedLine
        }
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the checkRefundService
 * @param customOptions
 * @returns
 */ export var useCheckRefund = function(fixedLine, customOptions) {
    var getCheckRefundSaved = localStorage.getItem("checkRefund");
    var getRefundDataFromStorage = !!getCheckRefundSaved && getCheckRefundSaved !== "undefined" && JSON.parse(getCheckRefundSaved);
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "checkRefund";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var result = checkRefundService(fixedLine, customOptions);
            return result;
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: !getRefundDataFromStorage && !!fixedLine,
            onSuccess: function(data) {
                localStorage.setItem("checkRefund ", JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - checkRefund: ", error);
                localStorage.setItem("checkRefund", JSON.stringify(errorMock("checkRefund", error)));
            }
        })
    };
    return handleUseQuery(options);
};
