import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-landing-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * API service to submit user's order
 * @param cartId
 * @param payload
 * @param customOptions
 * @param searchParams
 */ export var submitService = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(cartId, payload, customOptions, searchParams) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", nextClient.post("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.SUBMIT), payload, _object_spread({
                        searchParams: _object_spread({}, nextClient.searchParams, searchParams)
                    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
                        headers: customOptions.headers
                    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
                        silentLoginHeaders: customOptions.silentLoginHeaders
                    })));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function submitService(cartId, payload, customOptions, searchParams) {
        return _ref.apply(this, arguments);
    };
}();
/**
 * Mutation for saving into the key 'submitQuery'
 * the response coming from the API service to submit user's order
 * @returns
 */ export var useSubmitMutation = function() {
    var queryClient = useQueryClient();
    return useMutation("submitMutation", function() {
        var ref, ref1, ref2, ref3, ref4;
        var shoppingCartSaved = localStorage.getItem("shoppingCart");
        var shoppingCart = !!shoppingCartSaved && JSON.parse(shoppingCartSaved);
        LoggerInstance.debug("shoppingCart: ", shoppingCart);
        var cartId = shoppingCart && (shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id);
        var cartItems = shoppingCart && (shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.cartItem[0]);
        // Get customerData from local storage
        var customerSaved = localStorage.getItem("customerData");
        var customerData = !!customerSaved && JSON.parse(customerSaved);
        LoggerInstance.debug("customerData: ", customerData);
        var customerId = customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id;
        // Get deliveryDetails from local storage
        var deliveryDetailsSaved = localStorage.getItem("deliveryDetailsIsEvolved");
        var deliveryDetails = !!deliveryDetailsSaved && JSON.parse(deliveryDetailsSaved);
        var deliveryMethod = !!deliveryDetails && ((ref3 = (ref1 = deliveryDetails[0]) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.deliveryItems) === null || ref2 === void 0 ? void 0 : ref2[0].details) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : ref4.code);
        var payload = {
            customerId: customerId,
            isPortIn: true,
            deliveryMethod: deliveryMethod,
            productsInOrders: [
                {
                    orderItemId: cartItems.dataSim[0].id
                },
                {
                    orderItemId: cartItems.fixedVoice[0].id
                },
                {
                    orderItemId: cartItems.internet[0].id
                }, 
            ]
        };
        return submitService(cartId, payload, undefined, {
            bypassChargeDistribution: "true"
        });
    }, {
        onSuccess: function(data) {
            // save authorize object into storage
            localStorage.setItem("submitOrder", JSON.stringify(data));
            queryClient.setQueryData("submitQuery", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - submitQuery: ", error);
            queryClient.setQueryData("submitQuery", errorMock("submitQuery", error));
            localStorage.setItem("submitQuery", JSON.stringify(errorMock("submitQuery", error)));
        }
    });
};
export var useSubmitFixedMutation = function() {
    var queryClient = useQueryClient();
    return useMutation("submitMutation", function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var cartItems = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.cartItem[0];
        var customerData = getFromLocalStorageByKey("customerData");
        var customerId = customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id;
        var deliveryDetails = getFromLocalStorageByKey("deliveryDetailsIsAdvance");
        var getDeliveryCode = function(dI) {
            if ((dI === null || dI === void 0 ? void 0 : dI.length) > 0) {
                var details = [];
                dI === null || dI === void 0 ? void 0 : dI.forEach(function(el) {
                    var ref;
                    return details.push((el === null || el === void 0 ? void 0 : (ref = el.details) === null || ref === void 0 ? void 0 : ref[0]) || {});
                });
                var filteredItem = details === null || details === void 0 ? void 0 : details.find(function(itemToFilter) {
                    return itemToFilter === null || itemToFilter === void 0 ? void 0 : itemToFilter.isSelected;
                });
                return (filteredItem === null || filteredItem === void 0 ? void 0 : filteredItem.code) || "S";
            }
            return "T";
        };
        var deliveryMethod = getDeliveryCode((ref1 = deliveryDetails[0]) === null || ref1 === void 0 ? void 0 : ref1.deliveryItems);
        var payload = {
            customerId: customerId,
            isPortIn: true,
            deliveryMethod: deliveryMethod,
            productsInOrders: [
                {
                    orderItemId: cartItems === null || cartItems === void 0 ? void 0 : (ref2 = cartItems.dataSim) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.id
                },
                {
                    orderItemId: cartItems === null || cartItems === void 0 ? void 0 : (ref4 = cartItems.fixedVoice) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : ref5.id
                },
                {
                    orderItemId: cartItems === null || cartItems === void 0 ? void 0 : (ref6 = cartItems.internet) === null || ref6 === void 0 ? void 0 : (ref7 = ref6[0]) === null || ref7 === void 0 ? void 0 : ref7.id
                }, 
            ]
        };
        return submitService(cartId, payload, undefined, {
            bypassChargeDistribution: "true"
        });
    }, {
        onSuccess: function(data) {
            // save authorize object into storage
            localStorage.setItem("submitOrder", JSON.stringify(data));
            queryClient.setQueryData("submitQuery", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - submitQuery: ", error);
            queryClient.setQueryData("submitQuery", errorMock("submitQuery", error));
            localStorage.setItem("submitQuery", JSON.stringify(errorMock("submitQuery", error)));
        }
    });
};
export var useSubmitMobileMutation = function() {
    var queryClient = useQueryClient();
    return useMutation("submitMutation", function() {
        var ref, ref1, ref2, ref3;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var customerData = getFromLocalStorageByKey("customerData");
        var deliveryDetails = getFromLocalStorageByKey("postDeliveryServiceDetails");
        var portability = localStorage.getItem("portability");
        var deliveryMethod = (deliveryDetails === null || deliveryDetails === void 0 ? void 0 : (ref = deliveryDetails.deliveryDetails) === null || ref === void 0 ? void 0 : (ref1 = ref[0]) === null || ref1 === void 0 ? void 0 : ref1.code) || "";
        var cartId = shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id;
        var cartItems = shoppingCart === null || shoppingCart === void 0 ? void 0 : (ref2 = shoppingCart.cartItem) === null || ref2 === void 0 ? void 0 : ref2[0];
        var customerId = customerData === null || customerData === void 0 ? void 0 : (ref3 = customerData[0]) === null || ref3 === void 0 ? void 0 : ref3.id;
        var payload = {
            customerId: customerId,
            isPortIn: portability === "1",
            deliveryMethod: deliveryMethod,
            productsInOrders: [
                {
                    orderItemId: cartItems === null || cartItems === void 0 ? void 0 : cartItems.id
                }
            ],
            checkSimSwapCounter: false
        };
        return submitService(cartId, payload, undefined, {
            bypassChargeDistribution: "true"
        });
    }, {
        onSuccess: function(data) {
            // save authorize object into storage
            localStorage.setItem("submitOrder", JSON.stringify(data));
            queryClient.setQueryData("submitQuery", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - submitQuery: ", error);
            queryClient.setQueryData("submitQuery", errorMock("submitQuery", error));
            localStorage.setItem("submitQuery", JSON.stringify(errorMock("submitQuery", error)));
        }
    });
};
