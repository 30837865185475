import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-landing-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { errorMock } from "@vfit/consumer/data-access";
import { API, getFromLocalStorageByKey } from "@vfit/shared/data-access";
/**
 * API service to submit user's order
 * @param cartId
 * @param payload
 * @param customOptions
 */ export var submitWithPayloadService = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(cartId, payload, customOptions) {
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    return _ctx.abrupt("return", nextClient.post("".concat(API.ORDER, "/").concat(cartId, "/").concat(API.SUBMIT_WITH_PAYMENT), payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
                        headers: customOptions.headers
                    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
                        silentLoginHeaders: customOptions.silentLoginHeaders
                    })));
                case 1:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function submitWithPayloadService(cartId, payload, customOptions) {
        return _ref.apply(this, arguments);
    };
}();
export var useSubmitWithPaymentFixed = function() {
    var queryClient = useQueryClient();
    return useMutation("submitMutation", function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var customerData = getFromLocalStorageByKey("customerData");
        var billingAccount = getFromLocalStorageByKey("billingAccount");
        var deliveryData = getFromLocalStorageByKey("postDeliveryServiceDetails");
        var authorizeCC = getFromLocalStorageByKey("authorize");
        var portability = localStorage.getItem("portability");
        var cartId = shoppingCart && (shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id);
        var cartItems = shoppingCart && (shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.cartItem[0]);
        var customerId = customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id;
        var siaOrderId = authorizeCC === null || authorizeCC === void 0 ? void 0 : (ref1 = authorizeCC.authorizationParameters) === null || ref1 === void 0 ? void 0 : ref1.siaOrderId;
        var deliveryItems = [];
        var getDeliveryCode = function(dI) {
            if ((dI === null || dI === void 0 ? void 0 : dI.length) > 0) {
                var details = [];
                dI === null || dI === void 0 ? void 0 : dI.forEach(function(el) {
                    var ref;
                    return details.push((el === null || el === void 0 ? void 0 : (ref = el.details) === null || ref === void 0 ? void 0 : ref[0]) || {});
                });
                var filteredItem = details === null || details === void 0 ? void 0 : details.find(function(itemToFilter) {
                    return itemToFilter === null || itemToFilter === void 0 ? void 0 : itemToFilter.isSelected;
                });
                return (filteredItem === null || filteredItem === void 0 ? void 0 : filteredItem.code) || "S";
            }
            return "T";
        };
        var getFixedDeliveryItems = function(dItems) {
            var items = [];
            dItems === null || dItems === void 0 ? void 0 : dItems.forEach(function(el) {
                var ref;
                if ((el === null || el === void 0 ? void 0 : (ref = el.items) === null || ref === void 0 ? void 0 : ref.length) > 0) {
                    var ref1;
                    el === null || el === void 0 ? void 0 : (ref1 = el.items) === null || ref1 === void 0 ? void 0 : ref1.forEach(function(item) {
                        if (item) items.push(item);
                    });
                }
            });
            return items;
        };
        var updateDeliveryItems = function(dItems) {
            var dItemsOrganized = getFixedDeliveryItems(dItems || []);
            dItemsOrganized.forEach(function(el) {
                return deliveryItems.push(el);
            });
        };
        if (Array.isArray(deliveryData)) {
            deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.forEach(function(delivery) {
                updateDeliveryItems((delivery === null || delivery === void 0 ? void 0 : delivery.deliveryItems) || []);
            });
        } else {
            updateDeliveryItems((deliveryData === null || deliveryData === void 0 ? void 0 : deliveryData.deliveryItems) || []);
        }
        var haveAntType = deliveryItems === null || deliveryItems === void 0 ? void 0 : deliveryItems.some(function(el) {
            var ref;
            return ((ref = el.itemType) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "ant";
        });
        var deliveryMethod = getDeliveryCode(deliveryItems);
        if (haveAntType) deliveryMethod = "T";
        LoggerInstance.debug("SUBMIT PAYMENT deliveryDetails", deliveryItems, deliveryMethod);
        var payload = {
            customerId: customerId,
            immediatePayment: true,
            deliveryMethod: deliveryMethod,
            shipmentCountry: "IT",
            faId: ((ref2 = customerData === null || customerData === void 0 ? void 0 : customerData[0].financialAccount) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.id) || "",
            barId: (billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id) || "",
            isPortIn: portability === "1",
            productsInOrders: [
                {
                    orderItemId: cartItems === null || cartItems === void 0 ? void 0 : (ref4 = cartItems.dataSim) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : ref5.id
                },
                {
                    orderItemId: cartItems === null || cartItems === void 0 ? void 0 : (ref6 = cartItems.fixedVoice) === null || ref6 === void 0 ? void 0 : (ref7 = ref6[0]) === null || ref7 === void 0 ? void 0 : ref7.id
                },
                {
                    orderItemId: cartItems === null || cartItems === void 0 ? void 0 : (ref8 = cartItems.internet) === null || ref8 === void 0 ? void 0 : (ref9 = ref8[0]) === null || ref9 === void 0 ? void 0 : ref9.id
                }, 
            ],
            deliveryItems: deliveryItems,
            invoiceRequired: false,
            siaOrderId: siaOrderId,
            isFlexiFlow: false
        };
        LoggerInstance.debug("SUBMIT PAYMENT payload", payload);
        return submitWithPayloadService(cartId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("submitOrder", JSON.stringify(data));
            queryClient.setQueryData("submitQuery", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - submitQuery: ", error);
            queryClient.setQueryData("submitQuery", errorMock("submitQuery", error));
            localStorage.setItem("submitQuery", JSON.stringify(errorMock("submitQuery", error)));
        }
    });
};
export var useSubmitWithPaymentMobile = function() {
    var queryClient = useQueryClient();
    return useMutation("submitMutation", function() {
        var ref, ref1, ref2, ref3, ref4, ref5;
        var shoppingCart = getFromLocalStorageByKey("shoppingCart");
        var customerData = getFromLocalStorageByKey("customerData");
        var billingAccount = getFromLocalStorageByKey("billingAccount");
        var deliveryDetails = getFromLocalStorageByKey("postDeliveryServiceDetails");
        var authorizeCC = getFromLocalStorageByKey("authorize");
        var portability = localStorage.getItem("portability");
        var cartId = shoppingCart && (shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.id);
        var cartItems = shoppingCart && (shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.cartItem[0]);
        var customerId = customerData === null || customerData === void 0 ? void 0 : (ref = customerData[0]) === null || ref === void 0 ? void 0 : ref.id;
        var deliveryMethod = (deliveryDetails === null || deliveryDetails === void 0 ? void 0 : (ref1 = deliveryDetails.deliveryDetails) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.code) || "";
        var siaOrderId = authorizeCC === null || authorizeCC === void 0 ? void 0 : (ref3 = authorizeCC.authorizationParameters) === null || ref3 === void 0 ? void 0 : ref3.siaOrderId;
        LoggerInstance.debug("SUBMIT PAYMENT deliveryDetails", deliveryDetails);
        var payload = {
            customerId: customerId,
            immediatePayment: true,
            deliveryMethod: deliveryMethod,
            shipmentCountry: "IT",
            faId: ((ref4 = customerData === null || customerData === void 0 ? void 0 : customerData[0].financialAccount) === null || ref4 === void 0 ? void 0 : (ref5 = ref4[0]) === null || ref5 === void 0 ? void 0 : ref5.id) || "",
            barId: (billingAccount === null || billingAccount === void 0 ? void 0 : billingAccount.id) || "",
            isPortIn: portability === "1",
            productsInOrders: [
                {
                    orderItemId: cartItems === null || cartItems === void 0 ? void 0 : cartItems.id
                }
            ],
            deliveryItems: (deliveryDetails === null || deliveryDetails === void 0 ? void 0 : deliveryDetails.deliveryItems) || [],
            invoiceRequired: false,
            siaOrderId: siaOrderId,
            isFlexiFlow: false
        };
        LoggerInstance.debug("SUBMIT PAYMENT payload", payload);
        return submitWithPayloadService(cartId, payload);
    }, {
        onSuccess: function(data) {
            localStorage.setItem("submitOrder", JSON.stringify(data));
            queryClient.setQueryData("submitQuery", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - submitQuery: ", error);
            queryClient.setQueryData("submitQuery", errorMock("submitQuery", error));
            localStorage.setItem("submitQuery", JSON.stringify(errorMock("submitQuery", error)));
        }
    });
};
